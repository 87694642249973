import "./App.scss";
import { collection, addDoc } from "firebase/firestore";
import { db } from "./firebase";
import { useState } from "react";
import "./fonts/digitalGeometricBold.ttf";

function App() {
  const [emailValue, setEmailValue] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isSent, setIsSent] = useState(false);
  const [messageText, setMessageText] = useState("");

  const getEmailValue = (event) => {
    event.preventDefault();

    const email = emailValue;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email)) {
      console.log(email);
      setIsValidEmail(true);
      setIsSent(true);
      addData(email);
      setEmailValue("");
      setMessageText("You've successfully subscribed!");
    } else {
      console.log("Invalid email format");
      setIsValidEmail(false);

      setIsSent(false);
      setMessageText("Please enter a valid email address.");
    }
  };

  const addData = async (email) => {
    try {
      const docRef = await addDoc(collection(db, "emails"), {
        email: email,
      });
      console.log("Document written widh ID: ", docRef.id);
    } catch (e) {
      console.log("Error adding document: ", e);
    }
  };

  return (
    <div className="App">
      <div className="info">
        <div className="text">
          <div className="intro">
            <h1>Welcome to <span className="neuragest">neuragest</span></h1>
            <h2>Enter this AI-powered experience, and create art with every gesture.</h2>
          </div>
          <p className="info_text">
            This project is under development.<br></br>Leave your email and
            we'll keep you updated.
          </p>
        </div>
        <form className="form">
          <div>
            <input
              name="email"
              type="email"
              className={`input email ${isValidEmail ? "" : "invalid"}`}
              placeholder="Email address"
              value={emailValue}
              onChange={(e) => setEmailValue(e.target.value)}
            ></input>
            <p className={`message ${isValidEmail ? "sent" : "error"}`}>
              {messageText}
            </p>
          </div>
          <button className="button" onClick={getEmailValue}>
            <span>Submit</span>
          </button>
        </form>
      </div>
      <div className="footer">
        <p>
          A project by{" "}
          <a
            href="https://www.linkedin.com/in/marc-lopez-soler/"
            target="_blank"
          >
            Marc López
          </a>
        </p>
      </div>
    </div>
  );
}

export default App;
